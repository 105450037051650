<template>
  <div class="accountpanel-wrapper">
    <header class="accountpanel-head flex flex_acenter flex_jcbetween prel" style="z-index:1;">
      <!-- <img src="@/assets/login_logo.png" alt="" class="accountpanel_logo"> -->
      <!-- <div class="loginlanguage-outer" v-if="languageList.length > 0">
        <el-select v-model="currentLanguage" placeholder="" @change="languageChange" popper-class="loginlanguage" :popper-append-to-body="false" class="noborder"> 
          <i slot="prefix" class="icon-login-language fontsize_20 icon-language"></i>
          <el-option
            v-for="item in languageList"
            :key="item.key"
            :label="item.name"
            :value="item.key">
          </el-option>
        </el-select>
      </div> -->
    </header>
    <router-view></router-view>
    <footer class="accountpanel-footer flex flex_acenter flex_center prel" style="z-index:1;">
      <p class="fontsize_12 color_fff opacity_d8 pointer" >
        <a :href="'https://beian.miit.gov.cn/'" target="_blank"
          class="pointer">广州造父科技有限公司 © 粤ICP备20009658号-2</a>
      </p>
    </footer>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'layout',
  components: {
  },
  computed: {
    ...mapState({
      is_web: state => state.app.isWebSide,
      languageList: state => state.others.languageList,
      feidaoLanguage: state => state.others.feidaoLanguage,
    }),
  },
  data() {
    return {
      currentLanguage:"zh-CN",
    };
  },
  watch: {
    feidaoLanguage: {
      handler(n) {
        if(n) {
          this.currentLanguage = n;
        }
      },
      immediate: true
    }
  },
  created() {
    this.GetLanguageList();
  },
  methods: {
    ...mapActions(['GetLanguageList']),
    ...mapMutations(['setCurrentLanguage']),
    languageChange(val) {
      // 语言切换
      // val : en cn 
      let lang = "";
      switch(val) {
        case "zh-CN":
          lang = "zh";
          break;
        case "en":
          lang = "en";
          break;
      }
      this.$i18n.locale = lang;
      this.setCurrentLanguage(val);
    },
  },
}
</script>

<style  lang="less" scoped>
  .accountpanel-wrapper {
    box-sizing: border-box;
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden; //导致宽屏时，不显示最外层的滚动条
    height: 100vh;
    min-height: 720px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(~@/assets/login_bg_left.png);
    background-position: bottom 10px center;
    background-attachment: fixed;
    &::before {
      content: "";
      position: absolute;
      right: 20vw;
      top: 0;
      border: 40vw solid transparent;
      border-right-color: #FFFFFF;
      border-right-width: 40vw;
      border-top-width: 100vh;
      border-bottom-width: 0;
      z-index: 0;
    }
    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: 20vw;
      height: 100vh;
      background-color: #FFFFFF;
    }
  }
  .accountpanel-head {
    padding: 35px 35px 0;
    font-style: italic;
    font-weight: bolder;
    .accountpanel_logo {
      height:41px;
    }
  }
  .accountpanel-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 39px;
    background-color: #000;
    a {
      text-decoration: none;
      color: #FFFFFF;
      font-size: 12px;
      opacity: 0.8;
    }
  }
  // 语言切换
  .loginlanguage-outer {
    border-radius: 4px;
    border: 1px solid #FFFFFF;
    width: 90px;
    height: 34px;
    color: #FFFFFF;
    .el-select  {
      height: 34px;
    }
    /deep/.el-input__inner {
      padding-left: 36px;
      font-size: 12px;
      color: #FFFFFF!important;
    }
  }
  .icon-language {
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translate(0, -50%);
  }
  // 三角形实心
  /deep/.el-icon-arrow-up {
    font-family: "icomoon"!important;
    &.is-reverse {
      transform: rotateZ(0) scale(0.65)!important;
    }
    transform: rotateZ(180deg) scale(0.65)!important;
    &:before {
      font-size: 12px;
      content: "\e9da";
      color: #a1a1a1;
    }
  }
</style>
